import React from 'react'
import Retraits from '../surveil/components/retraits'

function Repreneur() {
  return (
    <div className="space-y-10">
    <Retraits />
   </div>
  )
}

export default Repreneur