import { Avatar, Box, Button, Card as MantineCard, Group, Image, LoadingOverlay, Modal, NumberFormatter, NumberInput, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { CompteService } from 'services/compte.service';
import { OperationService } from 'services/operation.service';
import useScanDetection from 'use-scan-detection';
import { useEffect, useState } from 'react';
import { useAppStore } from 'variables/store';
import { useSearchParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { DataTable } from 'mantine-datatable';
import InputField from 'components/fields/InputField';
import { add, format, isAfter, isBefore } from 'date-fns';
import { FaPrint, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';
import { TbSum } from "react-icons/tb";
import { DatePicker } from '@mantine/dates';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import fr from 'dayjs/locale/fr';
import Card from 'components/card';
import { validate } from 'uuid';
import pdfMake from "pdfmake";
import { drapeau } from '../default/drapeau';
import { logo } from '../marketplace/components/logo';
import { font } from 'vfs_fonts';
pdfMake.vfs = font;

const DepotSchema = yup.object().shape({
    montant: yup.number().required('Invalid Montant'),
  });
  const PAGE_SIZE = 15;

  const FN = new Intl.NumberFormat('sn-SN');
function Vendeurs() {
    const [opened, { open, close }] = useDisclosure(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortStatus, setSortStatus] = useState({
        columnAccessor: 'type',
        direction: 'asc',
      });
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [dateSearchRange, setDateSearchRange] = useState();
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [bodyRef] = useAutoAnimate();
    const {user} = useAppStore();
    const [qr,setQr] = useState()
    const qc = useQueryClient();
    const form = useForm({
        initialValues: {
            montant: 0,
        },
        validate: yupResolver(DepotSchema),
      });
    const compteService = new CompteService();
    const key = ['compte_depot'];
    const keyR = ['operation_by',user?._id];
    const {data,isLoading} = useQuery({
        queryKey: key,
        queryFn: () => compteService.byCode(qr),
        enabled:qr !== undefined,
     });
     const operationService = new OperationService();

     const {mutate:createDepot,isPending} = useMutation({
        mutationFn: (data) => operationService.depot(data),
        onSuccess: () => {
         close();
         qc.invalidateQueries(key);
         form.reset();
        }
     });
    const {data:operations,isLoading:isLoadingR} = useQuery({
        queryKey: keyR,
        queryFn: () => operationService.byResponsable(user?._id),
     });

      const onCreate = ({montant}) => {
        const compte = data?._id;
        const responsable =  {prenom:user?.prenom,nom:user?.nom,_id:user?._id};
        const responsable_id =  user?._id;
        createDepot({compte,montant,responsable,responsable_id});
      }

    useScanDetection({
      onComplete: (code) => { 
          const c = code.replace(/Shift/gi,"");
          if(validate(c)){
             setQr(c);
          }
         
        },
    });

    const filtered = (operations = []) => {
      return  operations?.filter(({ montant,createdAt }) => {
        if (
          debouncedQuery !== '' &&
          !`${montant}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        )
          return false;
  
          if (
            dateSearchRange &&
            dateSearchRange[0] &&
            dateSearchRange[1] &&
            (isAfter(dateSearchRange[0],createdAt) ||
             isBefore(dateSearchRange[1],createdAt))
          )
            return false;

        return true;
      })
    }
  
    useEffect(() => {
       if(debouncedQuery === ''){
        if(searchParams.has('page')){
          setPage(parseInt(searchParams.get('page')));
        }else {
          setPage(1);
        }
       }
       else {
        setPage(1);
       }
        
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        const data = sortBy(operations, sortStatus.columnAccessor);
      setRecords(sortStatus.direction === 'desc' ? (filtered(data).slice(from, to) ?? []).reverse() : filtered(data).slice(from, to) ?? []);
      }, [searchParams,page,operations,debouncedQuery,dateSearchRange,sortStatus]);

      const handlePrintRecord = () => {
        const docDefinition = {
          styles: {
            entete: {
                bold: true,
                alignment:'center',
                fontSize:10
            },
            center: {
                alignment:'center',
                fontSize:8,
                bold:true
            },
            left: {
              alignment:'left',
          },
          right: {
            alignment:'right',
        },
            nombre: {
              alignment:'right',
              fontSize:10,
              bold: true
          },
             info: {
              fontSize:8,
          },
           header3: {
                color:"white",
                fillColor: '#73BFBA',
                bold: true,
                alignment:'center',
                fontSize:6,
            },
            header4: {
              color:"white",
              fillColor: '#73BFBA',
              bold: true,
              alignment:'right',
              fontSize:6
          },
            total:{
                color:"white",
                bold: true,
                fontSize:6,
                fillColor:'#73BFBA',
                alignment:'center'
            },
            anotherStyle: {
              italics: true,
              alignment: 'right'
            }
          },
          content:[{
            columns: [
              {
              with: 'auto',
              alignment:'left',
              stack: [
                {text:"REPUBLIQUE DU SENEGAL\n",fontSize: 8,bold: true,alignment:"center"},
                {text:"Un Peuple, Un but, Une Foi\n",fontSize: 8,bold: true,margin:[0,2],alignment:"center"},
                {image:drapeau,width: 40,alignment:"center"},
                {text:"MINISTERE DE L'ENSEIGNEMENT SUPERIEUR DE LA RECHERCHE ET DE L'INNOVATION \n",fontSize: 8,bold: true,margin:[0,2],alignment:"center"},
                {text:"CENTRE REGIONAL DES OEUVRES UNIVERSITAIRES SOCIALES DE ZIGUINCHOR\n",fontSize: 8,bold: true,margin:[0,2],alignment:"center"},
              ]},
              
              {
                with:'auto',
                alignment:'right',
                stack:[
                  {image:logo,width: 80,alignment:"center"},
                  {text:`Ziguinchor Le : ${format(new Date(),'dd/MM/yyyy')}`,fontSize: 8,bold: true,alignment:"center"},
                ]
                
              }
            ]
          },
          {
            margin: [0,20],
            fillColor:"#422AFB",
            alignment:'center',
            layout:'noBorders',
            table: {
              widths: [500],
              body: [
                [ {text:`OPERATIONS DU ${format(new Date(),'dd/MM/yyyy')}`,fontSize: 16,bold: true,color:'white',margin:[0,4]}],
              ]
            }
          },
          {
            margin: [4,4,4,4],
            alignment: 'justify',
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#A3AED0' : null;
              }
            },
            table: {
              widths: ['5%','15%','10%','50%','20%'],
                body: [
                    [{text:'#',style:'entete'}, {text:'DATE',style:'entete'},{text:'HEURE',style:'entete'},{text:'DESCRIPTION',style:'entete'},{text:'MONTANT',style:'entete'}],
                     ...records?.map((k,i)=> (
                      [
                        {text:`${i+1}`,style:'info'},
                        {text:`${format(k.createdAt,'dd/MM/yyyy')}`,style:'info'},
                        {text: `${format(k.createdAt,'HH:mm:ss')}`,style:'info'},
                        {text: `${k.description}`,style:'info'},
                        {text: `${FN.format(k.montant)}`,style:'nombre'}
                    ]
                    )),
                    [{text: 'Montant Total',style:'info',colSpan:4},
                      '','','',
                      {text: `${FN.format(records?.reduce((acc,cur) => acc + cur.montant ,0))}`,style:'nombre',color:'white',fillColor:'#422AFB'}]
                  ]
            }
        },
        ]
        }
        
          pdfMake.createPdf(docDefinition).open();
      }


  return (
   <>
    <LoadingOverlay
       visible={isLoading || isLoadingR}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
     <div className="flex flex-col">
        <div className="flex h-full">
    <div className="w-8/12 flex items-center justify-center">
      <Image src="/qrcode.gif" />
    </div>
    {data && <div className='w-4/12'>
        <MantineCard withBorder padding="xl" radius="md" mt={65}>
            <MantineCard.Section
                h={150}
                style={{
                backgroundImage:'url("/banner.jpg")',
                }}
            />
            <Avatar
                src={`${process.env.REACT_APP_BACKURL_ETUDIANT}/${data.etudiant.avatar}`} 
                size={150}
                radius={80}
                mx="auto"
                mt={-30}
            />
            <Text ta="center" fz="lg" fw={500} mt="sm">
                {data.etudiant.prenom} {data.etudiant.nom}
            </Text>
            <Text ta="center" fz="sm" c="dimmed">
                {data.etudiant.ncs}
            </Text>
            <Text ta="center" fz="lg" fw={500} mt="sm">
                Solde : {data.solde} fcfa
            </Text>
            <Button fullWidth radius="md" mt="xl" size="md" variant="gradient" onClick={open}>
                FAIRE UN DEPOT
            </Button>
            </MantineCard>   
    </div>}
   </div>
   <Card>
   <div className="p-4">
   <div className="flex items-center justify-between w-full px-10">
        <Text fw="bold" c="#422AFB">
            MES OPERATIONS
          </Text>
        <div>
            <Button color="#422AFB" leftSection={<FaPrint />} onClick={handlePrintRecord}>IMPRIMER</Button>
       </div>
        </div>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
      
      { accessor: 'Date',textAlign: 'center', render:({createdAt}) => format(createdAt,'dd/MM/yyyy'),
      sortable:true,
      filter: ({ close }) => (
        <Stack>
          <DatePicker
            maxDate={add(new Date(),{days:1})}
            locale={fr}
            type="range"
            value={dateSearchRange}
            onChange={setDateSearchRange}
          />
          <Button
            disabled={!dateSearchRange}
            variant="light"
            onClick={() => {
              setDateSearchRange(undefined);
              close();
            }}
          >
            Effacer
          </Button>
        </Stack>
      ),
      filtering: Boolean(dateSearchRange),
     },
      { accessor: 'heure',textAlign: 'center', render:({createdAt}) => format(createdAt,'HH:mm:ss')}, 
      { accessor: 'description',textAlign: 'center'}, 
      {
        accessor: 'montant',
        textAlign: 'center',
        render:({montant}) => <NumberFormatter thousandSeparator="." decimalSeparator="," value={montant} suffix=' FCFA' />,
        sortable:true,
        footer: (
          <Group gap="xs" className="flex items-center justify-center">
            <Box mb={-4}>
              <TbSum size={24} />
            </Box>
            <div> <NumberFormatter thousandSeparator="." decimalSeparator="," value={records.reduce((acc,cur) => acc + cur.montant,0)} suffix=' FCFA' /></div>
          </Group>
        ),
      },
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoading}
    totalRecords={filtered(operations)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => {
      setSearchParams({'page':p});
      setPage(p);
    }
    }
    sortStatus={sortStatus}
    onSortStatusChange={setSortStatus}
    sortIcons={{
      sorted: <FaSortAlphaDownAlt size={14} />,
      unsorted: <FaSortAlphaDown size={14} />,
    }}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
    bodyRef={bodyRef}
  />
    </div>
    </Card>
     </div>
 
   <Modal opened={opened} onClose={close} title="CREATION D'UN DEPOT">
 <LoadingOverlay
       visible={isPending}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'bars' }}
     />
   <form onSubmit={form.onSubmit(onCreate)}>
         <NumberInput
      withAsterisk
      label="MONTANT"
      {...form.getInputProps('montant')}
       />
     <div className="my-5">
         <Button type="submit" bg="#422AFB">VALIDER</Button>
     </div>
     
   </form>
 </Modal>
   </>
  )
}

export default Vendeurs