import Api from "./Api";
import { Service } from "./Service";

export class ServiceService extends Service{
constructor(){
    super(Api,'service');
}

async byactive() {
    return this.api.get(`/${this.ressource}/active`).then(res => res.data);
  }

}