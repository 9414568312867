import React from "react";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdAccountBalanceWallet,
  MdLock,
  MdOutlineQrCodeScanner,
} from "react-icons/md";
import { FaChartLine, FaEye, FaHeadSideCough, FaUniversalAccess, FaUserGraduate, FaUsers } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import Users from "views/admin/users";
import Etudiants from "views/admin/etudiants";
import Comptes from "views/admin/comptes";
import Services from "views/admin/services";
import Vendeurs from "views/admin/vendeurs";
import Dashboard from "views/admin/default";
import Controlers from "views/admin/controllers";
import Surveil from "views/admin/surveil";
import Pubs from "views/admin/pub";
import Repreneur from "views/admin/repreneur";

const routes = [
  {
    name: "TABLEAU",
    layout: "/admin",
    path: "default",
    role: 'superadmin',
    icon: <FaChartLine className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: "UTILISATEURS",
    layout: "/admin",
    path: "utilisateurs",
    role: 'superadmin',
    icon: <FaUsers className="h-6 w-6" />,
    component: <Users />,
  },
  {
    name: "ETUDIANTS",
    layout: "/admin",
    path: "etudiants",
    role: 'admin',
    icon: <FaUserGraduate className="h-6 w-6" />,
    component: <Etudiants />,
  },
  {
    name: "COMPTES E-CAMPUS",
    layout: "/admin",
    role: 'admin',
    icon: <MdAccountBalanceWallet className="h-6 w-6" />,
    path: "comptes",
    component: <Comptes />,
  },
  {
    name: "SERVICES",
    layout: "/admin",
    role: 'superadmin',
    icon: <MdOutlineQrCodeScanner className="h-6 w-6" />,
    path: "services",
    component: <Services />,
  },
  {
    name: "PUBS",
    layout: "/admin",
    role: 'admin',
    icon: <FaHeadSideCough className="h-6 w-6" />,
    path: "pubs",
    component: <Pubs />,
  },
  {
    name: "COMPTABILITE",
    layout: "/admin",
    role: 'acp',
    icon: <FaEye className="h-6 w-6" />,
    path: "comptabilite",
    component: <Surveil />,
  },
  {
    name: "REPRENEUR",
    layout: "/admin",
    role: 'repreneur',
    icon: <FaEye className="h-6 w-6" />,
    path: "repreneur",
    component: <Repreneur />,
  },
  {
    name: "VENDEURS",
    layout: "/admin",
    role: 'vendeur',
    icon: <GiPayMoney className="h-6 w-6" />,
    path: "vendeurs",
    component: <Vendeurs />,
  },
  {
    name: "CONTROLLERS",
    layout: "/admin",
    role: 'controleur',
    icon: <FaUniversalAccess className="h-6 w-6" />,
    path: "controllers",
    component: <Controlers />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
