import { LoadingOverlay } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { MdOutlineQrCodeScanner } from "react-icons/md"
import { Link } from "react-router-dom";
import { ServiceService } from "services/service.service";

 const Controlers = () =>  {
const auth = useAuthUser();
  const serviceService = new ServiceService();
  const key = ['services-actives'];
  const {data:services,isLoading: isLoadingF} = useQuery({ queryKey: key, queryFn:() => serviceService.byactive() });
   
  return (
    <div>
       <LoadingOverlay
       visible={isLoadingF}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
     <div className="container relative z-40 mx-auto mt-12">
  <div className="flex flex-wrap justify-center mx-auto lg:w-full md:w-5/6 xl:shadow-small-green">
  {services?.filter(s => s.for_users.includes(auth?.id))?.map(s => (
      <Link  to={`${s._id}`} className="block w-1/2 py-10 text-center border lg:w-1/4 hover:scale-110">
      <div className="flex flex-col items-center justify-center">
        <MdOutlineQrCodeScanner size={80} color="blue" />

        <p className="pt-4 text-md font-semibold capitalize font-body lg:text-lg md:text-base md:pt-6">
          {s.nom}
        </p>
        <p className="pt-4 text-sm font-medium capitalize font-body lg:text-lg md:text-base md:pt-6">
          {s.prix} FCFA
        </p>
      </div>
    </Link>
  ))}
    
  </div>
</div>

    </div>
  )
}

export default Controlers